<template>
  <!-- begin register -->
  <div class="page-content">
    <!-- <div class="right-content"> -->
    <!-- begin card -->
    <div class="card bg-grey-lighter m-b-20 col-md-12 ">
      <div class="card-body  ">
        <blockquote class="blockquote ">
          <h2 class="page-header text-left text-black-lighter">修改密码</h2>
        </blockquote>
      </div>
    </div>
    <!-- end card -->
    <div class="register-content col-md-12 ">
      <form
        v-on:submit="checkForm"
        method="POST"
        data-parsley-validate="true"
        class="col-md-12"
      >
        <div class="row form-group m-b-10">
          <label class="col-md-2 col-form-label text-right"
            >请输入原密码：</label
          >
          <div class="col-md-3">
            <div class="input-group">
              <input
                type="password"
                
                class="form-control"
                v-model="password.oldpw"
                data-parsley-required="true"
                data-parsley-range="[5,10]"
                placeholder="OldPassword"
                required
              />
            </div>
          </div>
        </div>
        <div class="row form-group m-b-10">
          <label class="col-md-2 col-form-label text-right"
            >请输入新密码：</label
          >
          <div class="col-md-3">
            <div class="input-group">
              <input
                type="password"
                class="form-control"
                v-model="password.newpassword"
                data-parsley-required="true"
                placeholder="NewPassword"
                required
              />
            </div>
          </div>
        </div>
        <div class="row form-group m-b-30">
          <label class="col-md-2 col-form-label text-right"
            >请确认新密码：</label
          >
          <div class="col-md-3">
            <div class="input-group">
              <input
                type="password"
                class="form-control"
                v-model="password.newpassword1"
                data-parsley-required="true"
                required
              />
            </div>
          </div>
        </div>
        <label class="col-md-3 col-form-label "></label>
        <button type="submit" class=" col-xs-1 btn btn-sm btn-primary m-b-5 width-100">
          保存
        </button>
      </form>
    </div>
    <!-- end register-content -->
  </div>
</template>

<script>
// import PageOptions from '../config/PageOptions.vue'
import { userApi } from "@/api/system/userApi";

export default {
  data() {
    return {
      password: {
        oldpw: "",
        newpassword: "",
        newpassword1: "",
      },
    };
  },
  created() {
    // PageOptions.pageEmpty = true;
    document.body.className = "bg-white";
  },
  beforeRouteLeave(to, from, next) {
    // PageOptions.pageEmpty = false;
    document.body.className = "";
    next();
  },
  methods: {
    checkForm: function(e) {
      if (this.password.newpassword != this.password.newpassword1) {
        alert("两次输入的新密码不相同！");
      }
      e.preventDefault();
      console.log(this.password);
      const params = {
        CurrentPassword: this.password.oldpw,
        NewPassword: this.password.newpassword,
      };
      //   e.preventDefault();
      let that = this;
      //修改密码处理//
      userApi
        .chargePassword(params)
        .then((res) => that.chargeSuccess(that, res))
        // .catch((err) => that.requestFailed(err))
        .finally(() => {
          // state.loginBtn = false;
        });
    },
    chargeSuccess(that, res) {
      if (res.success == false && res.code == 500) {
        const Toast = this.$swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", this.$swal.stopTimer);
            toast.addEventListener("mouseleave", this.$swal.resumeTimer);
          },
        });

        Toast.fire({
          icon: "warning",
          title: res.message,
        });
      } else {
        console.log(res);
      }
    },
  },
};
</script>
